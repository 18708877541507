export const vidsList = [
    {
        type: "reels",
        links: [
            {
                id: 101,
                src: "https://player.vimeo.com/video/130055626?title=false&byline=false&portrait=false",
                alt: "Short Reel",
                title: "Short Reel"
            },
            {
                id: 102,
                src: "https://player.vimeo.com/video/130056350?title=false&byline=false&portrait=false",
                alt: "Long Reel",
                title: "Long Reel"
            }
        ],
        selected: true
    },
    {
        type: "shorts",
        links: [
            {
                id: 301,
                src: "https://player.vimeo.com/video/116576705?title=false&byline=false&portrait=false",
                alt: "THE SAINT OF AUSCHWITZ",
                title: "THE SAINT OF AUSCHWITZ",
                roles: "Director"
            },
            {
                id: 302,
                src: "https://player.vimeo.com/video/116575147?title=false&byline=false&portrait=false",
                alt: "ROY CAMPANELLA NIGHT",
                title: "ROY CAMPANELLA NIGHT",
                roles: "Director/Co-Writer"
            },
            {
                id: 303,
                src: "https://player.vimeo.com/video/116572872?title=false&byline=false&portrait=false",
                alt: "CLAUDE",
                title: "CLAUDE",
                roles: "Director/Co-Writer"
            },
            {
                id: 304,
                src: "https://player.vimeo.com/video/155266291?title=false&byline=false&portrait=false",
                alt: "THE '51 DONS",
                title: "THE '51 DONS",
                roles: "Director/Co-Writer"
            },
            {
                id: 205,
                src: "https://www.youtube.com/embed/XHgdYt7F2SY",
                alt: "ONE",
                title: "ONE",
                roles: "Director/Producer/Editor"
            },
            {
                id: 305,
                src: "https://www.youtube.com/embed/6oUaXDMQfiw",
                alt: "11:59PM",
                title: "11:59PM",
                roles: "Co-Director/Co-Writer"
            },
            {
                id: 306,
                src: "https://player.vimeo.com/video/125407563?title=false&byline=false&portrait=false",
                alt: "SWORD OF HONOR",
                title: "SWORD OF HONOR",
                roles: "Director/Writer"
            },
            {
                id: 307,
                src: "https://player.vimeo.com/video/220914391?title=false&byline=false&portrait=false",
                alt: "KORENGAL",
                title: "KORENGAL",
                roles: "Director/Writer"
            },
            {
                id: 308,
                src: "https://player.vimeo.com/video/220913381?title=false&byline=false&portrait=false",
                alt: "SURFMEN",
                title: "SURFMEN",
                roles: "Co-Director/Writer"
            }
        ],
        selected: false
    },
    {
        type: "branded",
        links: [
            {
                id: 401,
                src: "https://player.vimeo.com/video/500601001",
                alt: "Gabby Reece | Tru Believer Testimonial",
                title: "Gabby Reece | Tru Believer Testimonial",
                roles: "Director/Editor"
            },
            {
                id: 402,
                src: "https://player.vimeo.com/video/486510404",
                alt: "Shannon Sharpe | Tru Niagen®",
                title: "Shannon Sharpe | Tru Niagen®",
                roles: "Director/Producer/Editor"
            },
            {
                id: 408,
                src: "https://player.vimeo.com/video/393566087",
                alt: "Tru Niagen | Tru Believer",
                title: "Tru Niagen® | Tru Believer",
                roles: "Director/Editor"
            },
            {
                id: 410,
                src: "https://player.vimeo.com/video/441659068",
                alt: "Tru Niagen® - Stressors",
                title: "Tru Niagen® - Stressors",
                roles: "Director/Producer/Editor"
            },
            {
                id: 411,
                src: "https://player.vimeo.com/video/441658920",
                alt: "Tru Niagen® - Vulnerable",
                title: "Tru Niagen® - Vulnerable",
                roles: "Director/Producer/Editor"
            },
            {
                id: 412,
                src: "https://player.vimeo.com/video/441658758",
                alt: "Tru Niagen® - Viruses & NAD+",
                title: "Tru Niagen® - Viruses & NAD+",
                roles: "Director/Producer/Editor"
            },
            {
                id: 404,
                src: "https://www.youtube.com/embed/oia1ngjjlp4",
                alt: "Tru Niagen",
                title: "Tru Niagen®",
                roles: "Director/Producer"
            },
            {
                id: 400,
                src: "https://www.youtube.com/embed/k9_n1Mza3dE",
                alt: "Tru Niagen",
                title: "Meet Tru Niagen® | Explainer TV",
                roles: "Editor"
            },
            {
                id: 409,
                src: "https://player.vimeo.com/video/393831979",
                alt: "ChromaDex | Safety & Quality",
                title: "ChromaDex | Safety & Quality",
                roles: "Director/Producer/Cinematographer/Editor"
            },
            {
                id: 405,
                src: "https://player.vimeo.com/video/133176697?title=false&byline=false&portrait=false",
                alt: "Airrosti/CrossFit Games 2015 (Stacie's Story)",
                title: "Airrosti/CrossFit Games 2015 (Stacie's Story)",
                roles: "Director/Producer/Writer"
            },
            {
                id: 406,
                src: "https://player.vimeo.com/video/173025290?title=false&byline=false&portrait=false",
                alt: "Airrosti/CrossFit Games 2016 (Ben's Story)",
                title: "Airrosti/CrossFit Games 2016 (Ben's Story)",
                roles: "Director/Producer"
            },
            {
                id: 407,
                src: "https://player.vimeo.com/video/173025329?title=false&byline=false&portrait=false",
                alt: "Airrosti/CrossFit Games 2016 (Thank You, Airrosti)",
                title: "Airrosti/CrossFit Games 2016 (Thank You, Airrosti)",
                roles: "Director/Producer/Writer"
            },
            {
                id: 201,
                src: "https://player.vimeo.com/video/204487583?title=false&byline=false&portrait=false",
                alt: "AIRROSTI CARES FOR CROSSFIT:  PART ONE",
                title: "AIRROSTI CARES FOR CROSSFIT:  PART ONE",
                roles: "Director/Producer"
            },
            {
                id: 202,
                src: "https://player.vimeo.com/video/204488464?title=false&byline=false&portrait=false",
                alt: "AIRROSTI CARES FOR CROSSFIT:  PART TWO",
                title: "AIRROSTI CARES FOR CROSSFIT:  PART TWO",
                roles: "Director/Producer"
            },
            {
                id: 203,
                src: "https://player.vimeo.com/video/204489104?title=false&byline=false&portrait=false",
                alt: "AIRROSTI CARES FOR CROSSFIT:  PART THREE",
                title: "AIRROSTI CARES FOR CROSSFIT:  PART THREE",
                roles: "Director/Producer"
            },
            {
                id: 204,
                src: "https://player.vimeo.com/video/204489988?title=false&byline=false&portrait=false",
                alt: "AIRROSTI CARES FOR CROSSFIT:  PART FOUR",
                title: "AIRROSTI CARES FOR CROSSFIT:  PART FOUR",
                roles: "Director/Producer"
            }
        ],
        selected: false
    }
];

export const buttons = [
    {
        class: "reels",
        value: "reels",
        type: "Reels"
    },
    {
        class: "branded",
        value: "branded",
        type: "Branded"
    },
    {
        class: "shorts",
        value: "shorts",
        type: "Shorts"
    }
];