import React from 'react';
import { Link } from 'react-router-dom';

import '../../sass/SuccessContainer.scss'

export default function Success(props) {

    return (
        <div className="success-container">
            <div className="success-message">
                <div className="success-top">
                    <p>
                        Success!
                    </p>
                </div>

                <div className="success-bottom">
                    <p>
                        Thanks for your message, I'll get right back to you. 
                    </p>
                    <p>
                        Check out more of my work <Link to='/portfolio'>here</Link>.
                    </p>
                </div>
            </div>
        </div>
    )
}