import React, { useRef } from 'react';
import { Route, withRouter, NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import HomeContainer from './containers/HomeContainer';
import PortfolioContainer from './containers/PortfolioContainer';
import AboutContainer from './containers/AboutContainer';
import ContactContainer from './containers/ContactContainer';
import SuccessContainer from './containers/SuccessContainer';

import { ReactComponent as LinkedInSVG } from '../../src/svg/LinkedIn_Logo.svg';
import { ReactComponent as VimeoSVG } from '../../src/svg/Vimeo_Logo.svg';

import '../App.css';
import '../sass/Layout.scss';
import '../sass/Layout-media-queries.scss';
import '../sass/NavContainer.scss';
import '../sass/NavContainer-media-queries.scss';
import '../sass/FooterContainer.scss';
import '../sass/FooterContainer-media-queries.scss';

const routes = [
    { path: '/', name: 'home', Component: HomeContainer },
    { path: '/about', name: 'about', Component: AboutContainer },
    { path: '/portfolio', name: 'portfolio', Component: PortfolioContainer },
    { path: '/contact', name: 'contact', Component: ContactContainer },
    { path: '/success', name: 'success', Component: SuccessContainer }
];

const Layout = (props) => {
    const portfolioRef = useRef(null);
    const aboutRef = useRef(null);
    const contactRef = useRef(null);

    const smoothScroll = (e) => {
        const currentLinkRef = e.target.attributes.data.value;

        if (currentLinkRef === "Portfolio") {
            portfolioRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        } 
        
        if (currentLinkRef === "About") {
            aboutRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        } 
        
        if (currentLinkRef === "Contact") {
            contactRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        };
    };

    return (
        <div 
            className="layout-container"
            style={{
                transition: `all .5s ease-in-out`
			}}
        >
            <div className="nav-container">
                <nav role="navigation" className="nav">
                    <NavLink activeClassName="" exact to="/">
                        <h1 style={{color: `white`}}>Chris Commons</h1>
                    </NavLink>

                    <div className="title">
                        <p>Director • Producer • Writer Cinematographer • Editor</p>
                    </div>

                    <NavLink className="page-link" activeClassName="active" exact to={{pathname: "/portfolio"}} data="Portfolio" onClick={smoothScroll}>
                        Portfolio
                    </NavLink>

                    <NavLink className="page-link" activeClassName="active" exact to={{pathname: "/about"}} data="About" onClick={smoothScroll}>
                        About
                    </NavLink>

                    <NavLink className="page-link" activeClassName="active" exact to={{pathname: "/contact"}} data="Contact" onClick={smoothScroll}>
                        Contact
                    </NavLink>

                    <div className="nav-footer">
                        <div className="nav-footer-grow">
                            <a href="https://www.linkedin.com/in/chris-commons-0591367" target="_blank" rel="noopener noreferrer">
                                <LinkedInSVG fill={'white'} style={{ width: '35px', height: '35px' }}/>
                            </a>
                        </div>
                        <div className="nav-footer-grow">
                            <a href="https://vimeo.com/user1868769" target="_blank" rel="noopener noreferrer">
                                <VimeoSVG fill={'white'} style={{ width: '35px', height: '35px' }}/>
                            </a>
                        </div>
                    </div>
                </nav>
            </div>

            <div 
                className="page-container"
                style={{
                    transition: `all .5s ease-in`
                }}
            >
                {routes.map(({ path, Component }) => (
                    <Route key={path} exact path={path}>
                        {({ match }) => (
                            <CSSTransition
                                in={match != null}
                                timeout={300}
                                classNames="page"
                                unmountOnExit
                            >
                                <div className="page">
                                    <Component 
                                        style={{
                                            transition: `all .5s ease-in`
                                        }}
                                    />
                                </div>
                            </CSSTransition>
                        )}
                    </Route>
                ))}
            </div>
            
            <div 
                className="mobile-page-container"
                style={{
                    transition: `all .5s ease-in`
                }}
            >
                <div ref={portfolioRef} id="Portfolio">
                    <h3 className="mobile-header">Portfolio</h3>
                    <PortfolioContainer />
                </div>
                <div ref={aboutRef} id="About">
                    <h3 className="mobile-header">About</h3>
                    <AboutContainer />
                </div>
                <div ref={contactRef} id="Contact">
                    <h3 className="mobile-header">Contact</h3>
                    <ContactContainer />
                </div>
            </div>

            {/* <FooterContainer /> */}
        </div>
    )
}

export default withRouter(Layout);