import React, { useState, useRef, useEffect } from 'react';
// import { useSpring, animated } from 'react-spring';

import { ReactComponent as LeftArrowIconSVG } from '../../../src/svg/LeftArrow_Icon.svg';
import { ReactComponent as RightArrowIconSVG } from '../../../src/svg/RightArrow_Icon.svg';
import { ReactComponent as ListIconSVG } from '../../../src/svg/List_Icon.svg';
import { ReactComponent as GridIconSVG } from '../../../src/svg/Grid_Icon.svg';

import { vidsList, buttons } from '../../data/data';

import '../../sass/PortfolioContainer.scss';
import '../../sass/PortfolioContainer-media-queries.scss';

const PortfolioContainer = () => {
    const listRef = useRef(0);
    const [vidType, setVidType] = useState("reels");
    const [selected, setSelected] = useState(0);
    const [listItemSelected, setListItemSelected] = useState(-1);
    const [listOrGrid, setListOrGrid] = useState("grid");
    const [isScrolled, setIsScrolled] = useState(false);

    const filteredBySelected = vidsList.filter(vid => {
        return vid.type === vidType;
    });

    function selectType(value) {
        setVidType(value);
        setSelected(0);
        setListItemSelected(-1);

        if (listRef.current) {
            listRef.current.scrollLeft = 0 
        }
    }

    function selectListOrGrid(value) {
        setListOrGrid(value);
        setListItemSelected(-1);
    }
    
    function swapVid(e, selectedVid) {
        const selected = selectedVid;

        setSelected(selected);    
    }

    const leftButton = () => {
        return <div className="left-button"><button className="left-button-btn" onClick={e => scrollLeft()}><LeftArrowIconSVG fill={'white'} backgroundColor={'rgba(0, 0, 0, 0.0)'} style={{ width: '20px', height: '20px' }}/></button></div>
    }

    const rightButton = () => {
        return <div className="right-button"><button className="right-button-btn" onClick={e => scrollRight()}><RightArrowIconSVG fill={'white'} backgroundColor={'rgba(0, 0, 0, 0.0)'} style={{ width: '20px', height: '20px' }}/></button></div>
    }

    function scrollRight() {
        listRef.current.scrollLeft += 192;
        setIsScrolled(!isScrolled);
    };

    function scrollLeft() {
        listRef.current.scrollLeft -= 190;
        setIsScrolled(!isScrolled);
    }

    function isGreaterThanZero() {
        if(listRef.current) {
            if (listRef.current.scrollLeft > 0) {
                return true
            } 
        }
    }

    const handleListItemClick = (e, index) => {

        if (listItemSelected === index) {
            setListItemSelected(-1);
        } else {
            const selected = index;
            setListItemSelected(selected);
        }
    }

    // const isMaxed = () => {
    //     return listRef.current.scrollLeft === listRef.current.scrollWidth ? true : false; 
    // }

    useEffect(() => {
        isGreaterThanZero();
        // isMaxed();
    }, [listItemSelected]);

    return (
        <div className="portfolio-container">
            <div className="portfolio-main-container">
                <div className="portfolio-nav-container">
                    <div className="list-style-icon-container">
                        <button 
                            className={`portfolio-nav-button ${listOrGrid === 'grid' ? `selected` : null}`} 
                            onClick={e => selectListOrGrid("grid")}
                        >
                            <GridIconSVG />
                        </button>
                        <button 
                            className={`portfolio-nav-button ${listOrGrid === 'list' ? `selected` : null}`} 
                            onClick={e => selectListOrGrid("list")}
                        >
                            <ListIconSVG />
                        </button>
                    </div>

                    {buttons.map((button, index) => {
                        return (
                            <button 
                                key={index}
                                value={button.value} 
                                className={`portfolio-nav-button ${vidType === `${button.class}` ? `selected` : null}`} 
                                onClick={e => selectType(e.target.value)}
                            >
                                {button.type}
                            </button>
                        )
                    })}
                </div>



                {listRef && listOrGrid === 'grid' && 
                    <>
                        <div className="portfolio-player-container">
                            <iframe
                                    src={filteredBySelected[0].links[selected].src} 
                                    frameBorder="0" 
                                    webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen="true"
                                    alt={filteredBySelected[0].links[selected].alt} 
                                    title={filteredBySelected[0].links[selected].title}
                                />                    
                        </div>
                        
                        <div className="vid-title-container">
                            <h2 style={{color: `white`}}>{filteredBySelected[0].links[selected].title}</h2>
                        </div>

                        <div className="vid-roles-container">
                            <p>{filteredBySelected[0].links[selected].roles}</p>
                        </div>

                        <div className="portfolio-vids-container">
                            {isGreaterThanZero() && leftButton()}
                            <div className="portfolio-list-container" ref={listRef}>
                                {filteredBySelected[0].links.map((link, index) => {
                                    return (
                                        <div key={index} className={`portfolio-list-item ${index === selected ? `selected-vid` : null}`} onClick={e => swapVid(e, index)}>
                                            <iframe 
                                                key={index} 
                                                src={`${link.src}`} 
                                                frameBorder="0" 
                                                title={`${link.title}`} 
                                            />
                                        </div>
                                    )
                                })}
                            </div>    
                            {filteredBySelected[0].links.length > 3 ? rightButton() : null}   
                        </div>
                    </>
                }

                {listOrGrid === 'list' && 
                    <div className="project-list">
                        <ul>
                        {filteredBySelected[0].links.map((link, index) => {
                            return (
                                <li key={index} 
                                    data-id={index} 
                                    className={`${index === listItemSelected ? "project-list-item-selected" : "project-list-item"}`}
                                    onClick={e => handleListItemClick(e, index)}
                                >
                                    <div className="project-info">
                                        <div className="list-title-container">
                                            <p>{link.title}</p>
                                        </div>

                                        <div className="list-roles-container">
                                            <p>{link.roles}</p>
                                        </div>  
                                    </div>
                                    <div className={`${index === listItemSelected ? "project-list-vid-selected" : "project-list-vid"}`}>
                                        <iframe 
                                            src={`${link.src}`} 
                                            webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen="true"
                                            frameBorder="0" 
                                            title={`${link.title}`}
                                        /> 
                                    </div>
                                </li>
                            )
                        })}
                        </ul>
                    </div>
                }
            </div>
        </div>
    )
};

export default PortfolioContainer;
    