import React from 'react';

import '../../sass/AboutContainer.scss';
import '../../sass/AboutContainer-media-queries.scss';

import { ReactComponent as HallmarkSVG } from '../../../src/svg/Hallmark_Logo.svg';
import { ReactComponent as CrossfitSVG } from '../../../src/svg/Crossfit_Logo.svg';
import { ReactComponent as TruNiagenSVG } from '../../../src/svg/TruNiagen_Logo.svg';



const AboutContainer = () => {
    return (
        <div className="about-container">
            <div className="bio-container">
                <p>Chris Commons is a filmmaker currently based in Los Angeles.  A graduate of New York University's Tisch School of the Arts, he now works as a freelance writer, director, producer, cinematographer and editor.  He has created content for brands such as Hallmark, CrossFit and Tru Niagen.</p>
            </div>
            <div className="client-logos-container">
                <div className="client-logo">
                    <HallmarkSVG />
                </div>
                <div className="client-logo">
                    <CrossfitSVG />
                </div>
                <div className="client-logo">
                    <TruNiagenSVG />
                </div>
            </div>
        </div>
    )
}

export default AboutContainer;