import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

import '../../sass/ContactContainer.scss';
import '../../sass/ContactContainer-media-queries.scss';

const ContactContainer = (props) => {

    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [loading, setLoading] = useState(false);
    const [toSuccess, setToSuccess] = useState(false);

    let formattedMessage = `
    <body style="margin:6px;padding:6px;">
        <div style="height:60px;background-color:black;padding:20px;">
            <h1 style="color:white;">New form submission from your site!</h1>
        </div>
        <div style="box-sizing:border-box;color:black;background-color:lightgrey;padding:40px;">
            <p style="font-size:16px;margin-bottom:24px;max-width:420px;">${message}</p>
            <p style="margin-top:0px;margin-bottom:4px;margin-left:0px;margin-right:0px;font-size:20px;font-weight:bold;">${name && name}</p>
            <p style="margin-top:0px;margin-bottom:4px;margin-left:0px;margin-right:0px;font-size:18px;font-weight:bold;">${company && company}</p>
            <p style="margin-top:0px;margin-bottom:4px;margin-left:0px;margin-right:0px;font-size:18px;">${email && email}</p>
        </div>
        <div style="height:60px;background-color:black;padding:20px;">
            <p style="color:white;">If you're having issues with this email, please contact me at matt.tapper@gmail.com</p>
        </div>
    </body>
=      `;

    let contactData = {
        name,
        company,
        email,
        formattedMessage
    };

    function handleSubmit(e) {
        e.preventDefault();
        console.log('RUNNING');

        setLoading(true);

        axios({
            method: "POST", 
            url: "/.netlify/functions/server",
            // url: `${window.location.href}/submitForm`,
            // url: "/api/server",
            data: {...contactData}
        }).then((response) => {
            console.log('IT RESPONDED', response)
            if (response.status === 200) {
                setName("");
                setCompany("");
                setEmail("");
                setMessage("");
                setToSuccess(true);
            } else {
                alert("Message failed to send. Try again.");
            }
        })
    }

    return (
        <>
            {toSuccess ? <Redirect to="/success" /> : null}
            <div className="contact-container">
                <form 
                    className="contact-form"
                    onSubmit={handleSubmit}
                >
                    <div className="form-row">
                        <div className="form-input">
                            <input 
                                placeholder="Name"
                                type="text"
                                value={name.toString()}
                                aria-label="name"
                                autoComplete="none"
                                onChange={e => setName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-input">
                            <input 
                                placeholder="Email Address"
                                type="email"
                                value={email.toString()}
                                aria-label="email"
                                autoComplete="none"
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                    
                    <div className="form-row">
                        <div className="form-input">
                            <input 
                                placeholder="Company/Role"
                                type="text"
                                value={company.toString()}
                                aria-label="company"
                                autoComplete="none"
                                onChange={e => setCompany(e.target.value)}
                            />
                        </div>
                    </div>
                    
                    <div className="form-row">
                        <div className="form-textarea">
                            <textarea 
                                placeholder="Message"
                                rows="6"
                                value={message.toString()}
                                aria-label="message"
                                autoComplete="none"
                                onChange={e => setMessage(e.target.value)}
                            />
                        </div>
                    </div>
                    {!loading ? (
                        !toSuccess ? 
                            <button 
                                type="submit" 
                                className="form-submit-button" 
                                onClick={e => console.log(contactData)}
                            >
                                Submit
                            </button>
                        : (
                            <button 
                                type="submit" 
                                className="form-submit-button" 
                                onClick={e => e.preventDefault()}
                            >
                                Submitting
                            </button>
                        )
                    ) : (
                        <button 
                            type="submit" 
                            className="form-submit-button" 
                            onClick={e => console.log(contactData)}
                        >
                            Submitted!
                        </button>
                    )}
                </form>
            </div>
        </>
    )
}

export default ContactContainer;