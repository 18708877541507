import React from 'react';
// import logo from './logo.svg';
import './App.css';

import Layout from './components/Layout';

const App = () => {
	return (
		<main
			role='main'
			className='app-container'
			style={{
				transition: `all .5s ease-in-out`
			}}
		>
			<Layout />
		</main>
	);
};

export default App;
