import React from 'react';

import '../../sass/HomeContainer.scss';
import '../../sass/HomeContainer-media-queries.scss';

const HomeContainer = () => {
    return (
        <div className="home-container">
            <div className="home-player-container">
                <iframe 
                    src="https://player.vimeo.com/video/130055626?title=false&byline=false&portrait=false" 
                    frameBorder="0" 
                    alt="Chris Commons Reel" 
                    title="Chris Commons Reel"
                    webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen="true"
                />
            </div>
        </div>
    )
};

export default HomeContainer;
    